<div style="overflow: hidden; padding: 16px;">
    <h2 mat-dialog-title>{{createUpdatetitle |translate}}</h2>
    <small>{{'Please enter the name and parameters of your new company.' |translate}}</small>
    <p-toast key="resourceExists" position="bottom-center"></p-toast>
    <mat-dialog-content>
        <mat-form-field>
            <mat-label>{{'CompanyName'|translate}}</mat-label>
            <input matInput required>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'CompanyType'|translate}}</mat-label>
            <mat-select placeholder="{{'ChooseYourOption'|translate}}" [(ngModel)]="companyType" [required]="createUpdateMode === 'CREATE'">
                <mat-option *ngFor='let companyType of CompanyTypes' [value]="companyType">{{companyType}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="companyType === 'MC1'">
            <mat-label>{{'WtmKey'|translate}}</mat-label>
            <input matInput [(ngModel)]="wtmKey" required>
        </mat-form-field>
        <span *ngIf="createUpdateMode === 'CREATE'">
            <div class="scrollbar"
                style="border: 2px solid #E3E4EB; margin: 1px; padding: 16px; overflow: hidden; ">
                <div style="width: 100%; display: flex; justify-content: space-between; flex-direction: row; align-items: center;">
                    <div class="context-title-wrapper">
                        <mat-icon class="acount-tree">account_tree_outline</mat-icon>
                        <span class="role-n-context">{{'Context' |translate}}</span>
                    </div>
                    <div style="display: flex; justify-content: center; align-content: center;">
                        <small class="new-company-small">
                            New company on {{selectedOrganizationId}}
                        </small>
                    </div>
                </div>
                <mat-form-field>
                    <mat-label>{{'Enviroment'|translate}}</mat-label>
                    <input matInput [(ngModel)]="selectedEnvironmentId" required [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let env of envs" [value]="env">{{env}}</mat-option>
                    </mat-autocomplete>
                    <!-- <mat-select [placeholder]="'SelectEnvironment'|translate" [(value)]="selectedEnvironmentId"
                        [disabled]="!selectedOrganizationId">
                        <mat-option *ngFor="let env of envs" [value]="env">{{env}}</mat-option>
                    </mat-select> -->
                    <mat-icon style="margin-left: 8px;" matSuffix *ngIf="isLoadingContexts">
                        <i class="fa fa-spinner fa-spin"></i>
                    </mat-icon>
                </mat-form-field>

                <mat-form-field>
                    <mat-label>{{'CompanyId'|translate}}</mat-label>
                    <input matInput required [(ngModel)]="selectedCompanyId" [placeholder]="'SelectCompany'|translate" (ngModelChange)="onCompanySelected()">
                </mat-form-field>

                <div class="container" *ngIf="this.context">
                    <mat-icon class="icon">info</mat-icon>
                    <div class="text-box">
                        <h3 class="context-title">{{'contextPath' |translate}} </h3>
                        <span class="context-subTitle">{{'thisWillContext' |translate}}
                            <b>{{selectedOrganizationId}}/{{selectedEnvironmentId}}/{{selectedCompanyId}}</b></span>
                    </div>
                </div>

            </div>

            <div style="display: flex; justify-content: space-between; flex-direction: row; gap: 16px;">

                <div style="width: 100%;">
                    <mat-form-field>
                        <mat-label>{{'FirestoreRegion'|translate}}</mat-label>
                        <input type="text" placeholder="{{'ChooseYourOption'|translate}}" aria-label="Number" matInput
                            [(ngModel)]="firestoreRegion" [matAutocomplete]="autoFirestore" required>
                        <mat-autocomplete autoActiveFirstOption #autoFirestore="matAutocomplete">
                            <mat-option *ngFor='let fr of firestoreRegionOptions' [value]="fr[0]">
                                {{fr[0]}} {{fr[1]}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div class="see-all">See all firestore options <a
                            href="https://cloud.google.com/firestore/docs/locations"> here</a>
                    </div>
                </div>
                <div style="width: 100%;">
                    <mat-form-field>
                        <mat-label>{{'BucketRegion'|translate}}</mat-label>
                        <input type="text" placeholder="{{'ChooseYourOption'|translate}}" aria-label="Number" matInput
                            [(ngModel)]="bucketRegion" [matAutocomplete]="autoBucket" required>
                        <mat-autocomplete autoActiveFirstOption #autoBucket="matAutocomplete">
                            <mat-option *ngFor='let br of bucketRegionOptions' [value]="br[0]">
                                {{br[0]}} {{br[1]}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div class="see-all">See all bucket options <a
                            href="https://cloud.google.com/storage/docs/locations"> here</a>
                    </div>
                </div>
                <div style="width: 100%;">
                    <mat-form-field>
                        <mat-label>{{'BigqueryRegion'|translate}}</mat-label>
                        <input type="text" placeholder="{{'ChooseYourOption'|translate}}" aria-label="Number" matInput
                            [(ngModel)]="bigqueryRegion" (ngModelChange)="filterBigqueryRegions()"
                            [matAutocomplete]="autoBigquery" required>
                        <mat-autocomplete autoActiveFirstOption #autoBigquery="matAutocomplete">
                            <mat-optgroup *ngFor="let group of bigqueryRegionsfiltered" [label]="group.label">
                                <mat-option *ngFor="let br of group.regions" [value]="br[1]">
                                    {{br[0]}} <span style="margin-left: 20px;">{{br[1]}}</span><span
                                        style="float: right;"><!--img *ngIf="br[2]" src="/assets/images/gleaf.svg"
                                            alt="Low co2" /--></span>
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>
                    <div class="see-all">See all bigquery options <a
                            href="https://cloud.google.com/bigquery/docs/locations">
                            here</a>
                    </div>
                </div>
            </div>
        </span>

    </mat-dialog-content>

</div>
<mat-dialog-actions align="end" style="margin-bottom: 1px; background-color: #F4F4F8; width: 100%;">
    <button class="cancel" mat-button mat-dialog-close matTooltip="{{'CancelLower'|translate}}">
        {{'CancelLower'|translate}}
    </button>
    <button class="confirm" mat-button (click)="openConfirmDialog()" matTooltip="{{'SaveChanges'|translate}}">
        {{'Confirm'|translate}}
    </button>
</mat-dialog-actions>
