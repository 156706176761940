import { CommonModule } from "@angular/common"
import {
  Component,
  ContentChild,
  Input,
  OnInit,
  TemplateRef,
} from "@angular/core"

@Component({
  selector: "page-layout",
  standalone: true,
  templateUrl: "./page-layout.html",
  styleUrls: ["./page-layout.scss"],
  imports: [CommonModule],
  providers: [],
})
export class PageLayoutComponent implements OnInit {
  @Input() title: string = ""
  @Input() description?: string = ""
  @ContentChild("header") headerTemplate: TemplateRef<any>
  @ContentChild("content") contentTemplate: TemplateRef<any>

  constructor() {
  }

  ngOnInit(): void {}

  ngAfterContentInit() {}
}
