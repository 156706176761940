import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Company } from 'src/app/shared/api-structures/super-admin/company';
import { AutoDestroy } from 'src/app/shared/base-directives/auto-destroy';
import { LanguageService } from 'src/app/shared/services/language.service';
import { CompaniesService } from 'src/app/super-admin/services/companies.service';
import { ConfirmDialogComponent } from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { CompanySettingsComponent } from '../company-settings/company-settings.component';
import { CreateUpdateCompanyComponent } from '../create-update-company/create-update-company.component';
@Component({
  selector: 'companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss'],
})
export class CompaniesListComponent extends AutoDestroy implements OnInit {
  @Input() organizationId: string
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  totalCompanies: number
  filter: string = ''

  dataSource = new MatTableDataSource<Company>()
  columnsToDisplay = [
    'comapnyId',
    'environment',
    'name',
    'customers',
    'updatedAt',
    'customersIcon',
    'settings',
    'editIcon',
    'deleteIcon'
  ]
  CompanyToDelete: { id: string, environmentId: string }
  isShowConfirmDeleteDialog: boolean

  constructor(private companiesService: CompaniesService, private dialog: MatDialog,
    private languageService: LanguageService,) {
    super()
  }

  ngOnInit() {
    this.isShowConfirmDeleteDialog = false
    this.paginator.pageSize = 25;
    this.subscriptions.push(this.companiesService.listCompanies$.subscribe(d => {
      this.dataSource.data = d.items
      this.totalCompanies = d.totalProducts
      this.paginator.length = this.totalCompanies
      this.dataSource.sort = this.sort
    }))
  }

  applyFilter() {
    this.getCompanies()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async getCompanies() {
    const res = await this.companiesService.loadCompanies(this.organizationId, { currentPage: this.paginator.pageIndex, pageSize: this.paginator.pageSize, query: this.filter })
  }

  ondeleteClick(id: string, environmentId: string) {
    this.CompanyToDelete = { id, environmentId }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25vw',
      data: {
        title: `${this.languageService.translateSync('ConfirmDeletionTitle')}`,
        message: `${this.languageService.translateSync('confirmOrganizationDeletionMessage')} '${id}'?`
      }
    })
    dialogRef.afterClosed().subscribe(async confirmResult => {
      if (!confirmResult) {
        return
      }
      this.deleteCompany()
    })
  }
  async deleteCompany() {
    await this.companiesService.deleteCompany({ organization: this.organizationId, environment: this.CompanyToDelete.environmentId, company: this.CompanyToDelete.id })
    this.getCompanies()
  }

  async updateCompany(id: string, name: string, environmentId: string) {
    const dialogRef = this.dialog.open(CreateUpdateCompanyComponent, {
      width: '40vw',
      data: { id, name, environmentId, organizationId: this.organizationId }
    })
    dialogRef.afterClosed().subscribe(v => {
      if (v && v.isOrganizationUpdateOrCreated) {
        this.getCompanies()
      }
    })
  }

  popupSettings(organizationId, environmentId, companyId) {
    const dialogRef = this.dialog.open(CompanySettingsComponent, {
      panelClass: 'no-padding-dialog-container',
      width: '80vw',
      data: { companyId, environmentId, organizationId }
    })
  }

  export() {

  }

}
