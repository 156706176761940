<div class="page-layout px-4 px-sm-5 py-3">
  <!-- header -->
  <div
    class="flex justify-content-between align-content-center flex-xs-column flex-wrap"
  >
    <div class="flex flex-column flex-grow-1 breadcumb pr-3">
      <span class="title">{{ title}}</span>
      <span class="sub-title">{{ description }}</span>
    </div>

    <div class="flex justify-content-end py-3">
      <div>
        <ng-content select="page-layout-header"></ng-content>
        <!-- <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container> -->
      </div>
    </div>
  </div>

  <div class="mt-3 layout-content mat-elevation-z8 p-3">
    <ng-content></ng-content>

    <!-- <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container> -->
  </div>
</div>
