<div class="main-card" style="border: 2px solid #F0F0F1; border-radius: 8px;">
  <div class="pre-table-header">
    <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup.enter)="applyFilter()" [(ngModel)]="filter" #input>
    </mat-form-field>
  </div>
  <table mat-table class="full-width-table" matSort [dataSource]="dataSource" multiTemplateDataRows>

    <ng-container matColumnDef="comapnyId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'ComapnyId'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.id}}</td>
    </ng-container>

    <ng-container matColumnDef="environment">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Environment'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.environmentId}}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Name'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.name}}</td>
    </ng-container>

    <ng-container matColumnDef="customers">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'Customers'|translate}}</th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.totalCustomers">{{row.totalCustomers}}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="updatedAt">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'UpdatedAt'|translate}}</th>
      <td mat-cell *matCellDef="let row">{{row.lastUpdate | date : 'dd/MM/yyyy HH:mm' }}</td>
    </ng-container>
    <ng-container matColumnDef="settings">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
      </td>
    </ng-container>
    <ng-container matColumnDef="editIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row">
      </td>
    </ng-container>
    <ng-container matColumnDef="deleteIcon">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let row" class="delete-cell">
      </td>
    </ng-container>
    <div class="icon-container" style="display: flex; justify-content: space-between; flex-direction: row;">
      <ng-container matColumnDef="customersIcon">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let row" style="text-align: right;">
          <button matTooltip="{{'Customers'|translate}}" mat-button [routerLink]="['customers']"
            [queryParams]="{ organization_id :organizationId, company_id:row.id, environment_id:row.environmentId }">
            <mat-icon style="color:#919191;transform: scale(0.8);">supervisor_account</mat-icon>
          </button>
          <button matTooltip="{{'Settings'|translate}}" mat-button
            (click)="popupSettings(row.organizationId, row.environmentId, row.id)">
            <mat-icon style="color:#919191;transform: scale(0.8);">settings</mat-icon>
          </button>
          <button matTooltip="{{'Edit'|translate}}" mat-button
            (click)="updateCompany(row.id, row.name, row.environmentId)">
            <mat-icon fontSet="fa" fontIcon="fa-edit" style="color:#919191"></mat-icon>
          </button>
          <button matTooltip="{{'Delete'|translate}}" mat-button (click)="ondeleteClick(row.id)">
            <mat-icon style="transform: scale(0.9); color: #A41616;">delete_outline</mat-icon>
          </button>
        </td>
      </ng-container>
    </div>
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"> </tr>
  </table>
  <div class="card-footer">
    <button class="btn export" (click)="export()">
      Export <mat-icon class="mat-icon-export">arrow_downward</mat-icon>
    </button>
    <mat-paginator style="background-color: transparent;" [length]="dataSource.data.length"
      [pageSizeOptions]="[5, 10, 25, 100]" (page)="applyFilter()">
    </mat-paginator>
  </div>
</div>