<div class="settings-table-main">
  <div class="table-content-container">
    <div class="header-wrapper">
      <div class="title">
        <h4 style="margin-bottom: 8px; color: #0A131F;">{{'Settings'|translate}}</h4>
        <small>{{'Please enter the name and parameters of your new company.' | translate}}</small>
      </div>
    </div>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" animationDuration="0ms" *ngIf="companySettings"
      style="overflow: auto;">
      <mat-tab label="General">
        <div class="col-action-settings">
          <div>
            <div style="max-width: 365px;">
              <label class="margin-top">{{'Logo'|translate}}</label>
              <drag-and-drop-files isMultipleFiles="false" accept="image/*"
                (onFileSelected)="saveLogoFileToStorga($event)"
                *ngIf="!_logoUrl && !isLoadingSettings"></drag-and-drop-files>
            </div>
            <mat-card class="img-card mat-elevation-z0" *ngIf="_logoUrl">
              <button mat-mini-fab (click)="_logoUrl=undefined"
                class="img-icon"><mat-icon>add_a_photo</mat-icon></button>
              <img class="img-logo" mat-card-image src="{{_logoUrl}}" alt="LOGO" />
            </mat-card>
            <div *ngIf="uploadProgress">
              {{uploadProgress.name}}
              <mat-progress-bar mode="determinate" value="{{uploadProgress.progress}}"></mat-progress-bar>
            </div>
          </div>
          <br>
          <mat-slide-toggle class="custom-slide-toggle" [(ngModel)]="_isNoiseEnabled">
            {{'NoiseEnabled'|translate}}
          </mat-slide-toggle>
          <div style="display: flex; flex-direction: row; justify-content: space-between; gap: 16px; margin-top: 16px;">

            <mat-form-field>
              <mat-label>{{'Language'|translate}}</mat-label>
              <mat-select [(ngModel)]="_language">
                <mat-option value="pt">Portuguese</mat-option>
                <mat-option value="en">English</mat-option>
                <mat-option value="es">Spanish</mat-option>
                <mat-option value="ar">Arabic</mat-option>
                <mat-option value="el">Greek</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="countries.length > 0">
              <mat-label>{{'Country'|translate}}</mat-label>
              <mat-select [(ngModel)]="_country" (selectionChange)="changeCountry($event)">
                <mat-option></mat-option>
                <mat-option *ngFor="let country of countries" [value]="country.name">{{country.name}}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'Currency'|translate}}</mat-label>
              <input [disabled]="true" type="string" matInput [value]="_currency">
            </mat-form-field>
          </div>
        </div>
      </mat-tab>

      <!-- <mat-tab label="WTM" [disabled]="true">
                WTM -->
      <!-- Some configurations about WTM -->
      <!-- </mat-tab> -->

      <mat-tab label="Mobile">

        <div class="mobile-table"
          style="border: 1px solid #E1E1E3; background-color: white; border-radius: 4px; overflow: auto; max-height: 600px;">


          <div style="
            font-family: Inter;
            font-size: 14px;
            font-weight: 600;
            line-height: 21px;
            letter-spacing: 0.01em;
            text-align: left;
            color: #8E8E8E;
            border-bottom: 1px solid #E1E1E3;
            background-color: #F6F6F6; padding: 8px 16px;" class="row">
            <div class="col-1">
              Enable
            </div>
            <div class="col-5">Parameter</div>
            <div class="col-6"></div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle disabled></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"Classification Threshold Level" | translate}}
              </div>
              <div class="text2">
                {{"Set the threshold level for classification (Default: 50%)" | translate}}
              </div>
            </div>
            <div class="col-6">
              <div class="slider-text">
                {{'Threshold'|translate}} ({{_threshold}}%)
              </div>
              <mat-slider id="matSlider" max="100" min="0" step="1" thumbLabel [(ngModel)]="_threshold">
              </mat-slider>
            </div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle disabled></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"Android Image Quality" | translate}}
              </div>
              <div class="text2">
                {{"Adjust the image quality for Android devices. (Default: 50%)" | translate}}
              </div>
            </div>
            <div class="col-6">

              <div class="slider-text">
                {{'ImagesQualityAndroid'|translate}} {{_imagesQualityAndroid}}%</div>
              <mat-slider max="100" min="0" step="1" thumbLabel [(ngModel)]="_imagesQualityAndroid">
              </mat-slider>

            </div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle disabled></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"iOS Image Quality" | translate}}
              </div>
              <div class="text2">
                {{"Adjust the image quality for iOS devices. (Default: 50%)" | translate}}
              </div>
            </div>
            <div class="col-6">
              <div class="slider-text">
                {{'ImagesQualityIOS'|translate}} {{_imagesQualityIOS}}%
              </div>
              <mat-slider max="100" min="0" step="1" thumbLabel [(ngModel)]="_imagesQualityIOS">
              </mat-slider>

            </div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"Automatic Cropping" | translate}}
              </div>
              <div class="text2">
                {{"Enable or disable automatic cropping on the camera." | translate}}
              </div>
            </div>
            <div class="col-6"></div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"Enable as Partner " | translate}}
              </div>
              <div class="text2">
                {{"Make this company available as a partner on Store Shelfie." | translate}}
              </div>
            </div>
            <div class="col-6"></div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"Key Performance Indicators" | translate}}
              </div>
              <div class="text2">
                {{"Select the performance indicators to display on the mobile results screen." | translate}}
              </div>
            </div>
            <div class="col-6">

              <mat-form-field>
                <mat-label>{{'Kpis'|translate}}</mat-label>
                <mat-select >
                  <mat-option value="manual">{{'TODO'|translate}}</mat-option>
                  <mat-option value="automatic">{{'TODO'|translate}}</mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"Detection Zones" | translate}}
              </div>
              <div class="text2">
                {{"Select which detection zones you want to enable in the mobile photo editing screen" | translate}}
              </div>
            </div>
            <div class="col-6">

              <mat-form-field>
                <mat-label>{{'Zones Selection'|translate}}</mat-label>
                <mat-select >
                  <mat-option value="manual">{{'TODO'|translate}}</mat-option>
                  <mat-option value="automatic">{{'TODO'|translate}}</mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle disabled></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"Annotation Settings" | translate}}
              </div>
              <div class="text2">
                {{"Select the annotations to display on the mobile annotation screen." | translate}}
              </div>
            </div>
            <div class="col-6">

              <mat-form-field>
                <mat-label>{{'Annotations Selection'|translate}}</mat-label>
                <mat-select >
                  <mat-option value="manual">{{'TODO'|translate}}</mat-option>
                  <mat-option value="automatic">{{'TODO'|translate}}</mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"Price OCR" | translate}}
              </div>
              <div class="text2">
                {{"Activate automatic price recognition and display prices on your mobile device." | translate}}
              </div>
            </div>
            <div class="col-6"></div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"Sync Edited Results Only" | translate}}
              </div>
              <div class="text2">
                {{"Sync only results that have been edited on the mobile annotation screen." | translate}}
              </div>
            </div>
            <div class="col-6"></div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle disabled></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"Blur Detection Level" | translate}}
              </div>
              <div class="text2">
                {{"Set the threshold level for detecting blurriness in images." | translate}}
              </div>
            </div>
            <div class="col-6">

              <mat-form-field>
                <mat-label>{{'Blur Detection Threshold'|translate}}</mat-label>
                <mat-select >
                  <mat-option value="manual">{{'TODO'|translate}}</mat-option>
                  <mat-option value="automatic">{{'TODO'|translate}}</mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle disabled></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"Overlay Boundaries" | translate}}
              </div>
              <div class="text2">
                {{"Define the boundaries for overlays on images." | translate}}
              </div>
            </div>
            <div class="col-6">

              <mat-form-field>
                <mat-label>{{'Overlay Bounds'|translate}}</mat-label>
                <mat-select >
                  <mat-option value="manual">{{'TODO'|translate}}</mat-option>
                  <mat-option value="automatic">{{'TODO'|translate}}</mat-option>
                </mat-select>
              </mat-form-field>
              
            </div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle disabled></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"Blur Detection Capture Attempts" | translate}}
              </div>
              <div class="text2">
                {{"Set the number of attempts to capture a clear image before showing a blur warning." | translate}}
              </div>
            </div>
            <div class="col-6">

              <mat-form-field>
                <mat-label>{{'Blur Detection Capture Tries'|translate}}</mat-label>
                <mat-select >
                  <mat-option value="manual">{{'TODO'|translate}}</mat-option>
                  <mat-option value="automatic">{{'TODO'|translate}}</mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
          <div style="border-bottom: 1px solid #E1E1E3; background-color: 
            white; " class="row">
            <div class="col-1"><mat-slide-toggle disabled></mat-slide-toggle></div>
            <div class="col-5">
              <div class="text1">
                {{"OCR Read Method" | translate}}
              </div>
              <div class="text2">
                {{"Define the method for recognizing text in images on mobile devices." | translate}}
              </div>
            </div>
            <div class="col-6">

              <mat-form-field>
                <mat-label>{{'OCRReadMethod'|translate}}</mat-label>
                <mat-select [(ngModel)]="_ocrReadMethod">
                  <mat-option value="manual">{{'Manual'|translate}}</mat-option>
                  <mat-option value="automatic">{{'Automatic'|translate}}</mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>


        </div>

        <!--div class="col-action-settings" style="display: flex;">
          <div style="width: 50%;">
            <mat-slide-toggle [(ngModel)]="_syncOnlyEditedImages">
              {{'SyncOnlyEditedResults'|translate}}
            </mat-slide-toggle><br>
            <mat-slide-toggle [(ngModel)]="_galeryPhotoEnabled">
              {{'GaleryPhotoEnabled'|translate}}
            </mat-slide-toggle><br>
            <mat-slide-toggle [(ngModel)]="_singlePicturePriceOcr">
              {{'SinglePicturePriceOcr'|translate}}
            </mat-slide-toggle><br>
            <mat-slide-toggle [(ngModel)]="_multiPicturePriceOcr">
              {{'MultiPicturePriceOcr'|translate}}
            </mat-slide-toggle><br><br>

            <div style="display: flex;">
              <div style="flex: 1;">
                <b>&nbsp;&nbsp;Mobile Tabs (Full User)</b>
                <div *ngFor="let item of defaultMobileTabs.fullUser">
                  &nbsp;&nbsp;<mat-checkbox [ngModel]="selectTabsFull[item]"
                    (change)="removeFromListFullUser($event.checked, item)">{{item}}</mat-checkbox>
                </div>
              </div>

              <div style="flex: 1;">
                <b>&nbsp;&nbsp;Mobile Tabs (Simple User)</b>
                <div *ngFor="let item of defaultMobileTabs.simpleUser">
                  &nbsp;&nbsp;<mat-checkbox [ngModel]="selectTabsSimple[item]"
                    (change)="removeFromListSimpleUser($event.checked, item)">{{item}}</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 50%;">

            {{'Threshold'|translate}} ({{_threshold}})
            <mat-slider id="matSlider" max="100" min="0" step="1" thumbLabel [(ngModel)]="_threshold">
            </mat-slider>
            <div>
              {{'ImagesQualityAndroid'|translate}} {{_imagesQualityAndroid}}%
              <mat-slider max="100" min="0" step="1" thumbLabel [(ngModel)]="_imagesQualityAndroid">
              </mat-slider>
            </div>
            <div>
              {{'ImagesQualityIOS'|translate}} {{_imagesQualityIOS}}%
              <mat-slider max="100" min="0" step="1" thumbLabel [(ngModel)]="_imagesQualityIOS">
              </mat-slider>
            </div>

            <mat-form-field>
              <mat-label>{{'BlurDetection'|translate}} {{'Threshold'|translate}}</mat-label>
              <input type="number" matInput [(ngModel)]="_blurDetectionThreshold">
            </mat-form-field><br>
            <mat-form-field>
              <mat-label>{{'OverlayBounds'|translate}}</mat-label>
              <input type="number" matInput [(ngModel)]="_overlayBounds">
            </mat-form-field><br>
            <mat-form-field>
              <mat-label>{{'BlurDetection'|translate}} {{'CaptureTries'|translate}}</mat-label>
              <input type="number" matInput [(ngModel)]="_blurDetectionCaptureTries">
            </mat-form-field><br>
            <mat-form-field>
              <mat-label>{{'OCRReadMethod'|translate}}</mat-label>
              <mat-select [(ngModel)]="_ocrReadMethod">
                <mat-option value="manual">{{'Manual'|translate}}</mat-option>
                <mat-option value="automatic">{{'Automatic'|translate}}</mat-option>
              </mat-select>
            </mat-form-field><br>
          </div>
        </!--div-->
      </mat-tab>

      <!-- <mat-tab label="Multi Company" [disabled]="companySettings.companyType !== 'MC1'">
                <mat-form-field>
                    <div class="col-action-settings">
                        <mat-label>{{'Company'|translate}}</mat-label>
                        <input type="string" matInput [(ngModel)]="_sharingProjectCompany">
                    </div>
                </mat-form-field>
                <mat-slide-toggle [checked]="_sharingProjectEnabled">
                    {{'Enabled'|translate}}
                </mat-slide-toggle>
                <mat-form-field>
                    <div class="col-action-settings">
                        <mat-label>{{'Environment'|translate}}</mat-label>
                        <input type="string" matInput [(ngModel)]="_sharingProjectEnvironment">
                    </div>
                </mat-form-field>
                <mat-form-field>
                    <div class="col-action-settings">
                        <mat-label>{{'Organization'|translate}}</mat-label>
                        <input type="string" matInput [(ngModel)]="_sharingProjectOrganization">
                    </div>
                </mat-form-field>
            </mat-tab> -->
      <mat-tab label="{{'Integration'|translate}}" [disabled]="companySettings.companyType !== 'MC1'">
        <mat-form-field>
          <mat-label>{{'Mc1 DataSource'|translate}}</mat-label>
          <input type="string" matInput [(ngModel)]="_mc1DataSourceUrl">
        </mat-form-field>
        <!-- <mat-form-field>
                    <div class="col-action-settings">
                        <mat-label>{{'Company'|translate}}</mat-label>
                        <input type="string" matInput [(ngModel)]="_sharingProjectCompany">
                        <mat-label>{{'Organization'|translate}}</mat-label>
                        <input type="string" matInput [(ngModel)]="_sharingProjectOrganization">
                    </div>
                </mat-form-field> -->
      </mat-tab>

      <mat-tab label="Training">
        <div class="col-action-settings">
          <div>
            <mat-form-field>
              <mat-label>{{'SingleTrainingSold'|translate}}</mat-label>
              <input type="number" matInput [(ngModel)]="_trainingSettingsSingleTrainingSold" placeholder="Ex. 1">
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{'TrainingQuota'|translate}}</mat-label>
              <input type="number" matInput [(ngModel)]="_trainingSettingsTrainingQuota" placeholder="Ex. 1">
            </mat-form-field>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{'Catalog'|translate}}">
        <div class="col-action-settings">
          <mat-slide-toggle [(ngModel)]="_showCatalogDimensions">
            {{'ShowCatalogDimensions'|translate}}
          </mat-slide-toggle>
        </div>
      </mat-tab>


      <mat-tab label="Images Review">


        <div class="slider-row-wrapper">
          <div class="slider-wrapper">
            <p>{{ "Amount" | translate }}</p>
            <mat-slider class="my-slider-2" [min]="minimumImages" [max]=" 1500" step="1" [(value)]="maximumImages">
            </mat-slider>
          </div>
          <div class="center">
            <aside id="info-block">
              <section class="file-marker">
                <div class="text-center">
                  <div class="box-title">min</div>
                  <div class="box-contents">
                    <div id="audit-trail">
                      <div class="input-wrapper">
                        <input type="number" pattern="[0-9]*" class="no-border w100" [(ngModel)]="minimumImages" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </aside>
          </div>
          <div class="center">
            <div class="separator"></div>
          </div>
          <div class="center">
            <aside id="info-block">
              <section class="file-marker">
                <div class="text-center">
                  <div class="box-title">max</div>
                  <div class="box-contents">
                    <div id="audit-trail">
                      <div class="input-wrapper">
                        <input type="number" pattern="[0-9]*" class="no-border w100" [(ngModel)]="maximumImages" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </aside>
          </div>
        </div>

      </mat-tab>

      <!--mat-tab label="Dashboard">
        <mat-accordion multi>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Data Studio
              </mat-panel-title>
            </mat-expansion-panel-header>
            <br>
            <div class="dragdrop-list" cdkDropList (cdkDropListDropped)="dropDataStudio($event)">
              <div class="row dragdrop-box" *ngFor="let screen of _dataSourceDataStudio" cdkDrag>
                <div class="dragdrop-custom-placeholder" *cdkDragPlaceholder></div>
                <div class="col" style="max-width: 50px;"><mat-icon>menu</mat-icon></div>
                <div class="col">
                  <mat-label>Title</mat-label>
                  <input type="string" matInput [(ngModel)]="screen.title">
                </div>
                <div class="col">
                  <mat-label>Url</mat-label>
                  <input type="string" matInput [(ngModel)]="screen.url">
                </div>
                <div class="col">
                  <button mat-button (click)="removeItemFromDataStudioDatasource(screen)" cdkFocusInitial
                    matTooltip="{{'REMOVE'|translate}}">
                    <mat-icon color="primary">remove_circle</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <br>
            <di v  class="row">
              <div class="col">
                <mat-form-field style="width: 500px !important;">
                  <mat-label>Title</mat-label>
                  <input type="string" matInput [(ngModel)]="_dataStudioAddTitle">
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field style="width: 500px !important;">
                  <mat-label>Url</mat-label>
                  <input type="string" matInput [(ngModel)]="_dataStudioAddUrl">
                </mat-form-field>
              </div>
              <button mat-button (click)="addInputDataStudio()" cdkFocusInitial
                matTooltip="{{'SaveChanges'|translate}}">
                <mat-icon color="primary">add_circle</mat-icon>
              </button>
            </di>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Metabase
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-form-field style="width: 500px !important;">
              <mat-label>Secret Key</mat-label>
              <input type="string" matInput [(ngModel)]="_metabaseSecretKey">
            </mat-form-field>
            <br>
            <mat-form-field style="width: 500px !important;">
              <mat-label>Site URL</mat-label>
              <input type="string" matInput [(ngModel)]="_metabaseSiteUrl">
            </mat-form-field>
            <br>
            <div class="dragdrop-list" cdkDropList (cdkDropListDropped)="dropMetabase($event)">
              <div class="row dragdrop-box" *ngFor="let screen of _dataSourceMetabase" cdkDrag>
                <div class="dragdrop-custom-placeholder" *cdkDragPlaceholder></div>
                <div class="col" style="max-width: 50px;"><mat-icon>menu</mat-icon></div>
                <div class="col">
                  <mat-label>Title</mat-label>
                  <input type="string" matInput [(ngModel)]="screen.title">
                </div>
                <div class="col">
                  <mat-label>Id</mat-label>
                  <input type="string" matInput [(ngModel)]="screen.id">
                </div>
                <div class="col">
                  <button mat-button (click)="removeItemFromMetabaseDatasource(screen)" cdkFocusInitial
                    matTooltip="{{'REMOVE'|translate}}">
                    <mat-icon color="primary">remove_circle</mat-icon>
                  </button>
                </div>
              </div>
            </div>
            <br>
            <di v  class="row">
              <div class="col">
                <mat-form-field style="width: 500px !important;">
                  <mat-label>Title</mat-label>
                  <input type="string" matInput [(ngModel)]="_metabaseAddTitle">
                </mat-form-field>
              </div>
              <div class="col">
                <mat-form-field style="width: 500px !important;">
                  <mat-label>Id</mat-label>
                  <input type="number" matInput [(ngModel)]="_metabaseAddId">
                </mat-form-field>
              </div>
              <button mat-button (click)="addInputMetabase()" cdkFocusInitial matTooltip="{{'SaveChanges'|translate}}">
                <mat-icon color="primary">add_circle</mat-icon>
              </button>
            </di>
          </mat-expansion-panel>
        </mat-accordion>
      </!--mat-tab-->
    </mat-tab-group>
  </div>
  <mat-dialog-actions align="end" style="margin-bottom: 1px; background-color: #F4F4F8; width: 100%;">
    <button class="cancel" mat-button mat-dialog-close matTooltip="{{'CancelLower'|translate}}">
      {{'CancelLower'|translate}}
    </button>
    <button class="confirm" mat-button (click)="saveSettings()" matTooltip="{{'SaveChanges'|translate}}">
      {{'Confirm'|translate}}
    </button>
  </mat-dialog-actions>
</div>