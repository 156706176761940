<page-layout
  [title]="'Planograms' | translate"
  [description]="'PlanogramsCreatedByTheEditor' | translate"
>
  <page-layout-header>
    <button
      mat-flat-button
      color="primary"
      class="button-desc"
      (click)="addNewPlanogram()"
    >
      <mat-icon>add</mat-icon>&nbsp;&nbsp;{{ "AddNEW" | translate }}
    </button>
  </page-layout-header>

  <div class="table">
    <table-layout>
      <table-layout-caption>
        <div class="flex justify-content-between align-items-center">
          <div class="flex-grow-1">
            <mat-form-field class="aligned">
              <input
                matInput
                (keyup)="
                  listPlanogramsForAutocomplete($event, $event.target.value)
                "
                (keydown.enter)="applyFilter()"
                [(ngModel)]="searchPlanogramsValue"
                placeholder="{{ 'SearchPlanogram' | translate }}"
                [matAutocomplete]="searchPlanogramsAutocomplete"
              />
              <mat-autocomplete
                #searchPlanogramsAutocomplete="matAutocomplete"
                (optionSelected)="applyFilter()"
                (keydown.enter)="applyFilter()"
              >
                <mat-option
                  *ngFor="let planogramName of filterPlanograms"
                  [value]="planogramName"
                >
                  {{ planogramName }}
                </mat-option>
              </mat-autocomplete>
              <mat-icon matSuffix *ngIf="autocompleteShowSpinner">
                <i class="fa fa-spinner fa-spin"></i>
              </mat-icon>
            </mat-form-field>
          </div>

          <div class="action-area"></div>
        </div>
      </table-layout-caption>

      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="custom-table"
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>{{ "code" | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.mc1PlanogramId }}
            <span
              *ngIf="
                row.mc1PlanogramId === null || row.mc1PlanogramId === undefined
              "
              >{{ row.id }}</span
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "Name" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>{{ "Type" | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ displayPlanogramType(row.planogramType) | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="validity">
          <th mat-header-cell *matHeaderCellDef>
            {{ "Validity" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ formatDate(row.validity) }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            {{ "Status" | translate }}

            <button
              mat-button
              style="min-width: 1%"
              [matMenuTriggerFor]="statusesMenu"
            >
              <mat-icon>filter_list</mat-icon>
            </button>

            <mat-menu #statusesMenu="matMenu">
              <button
                (click)="filterByStatus(PLANOGRAM_STATUS.PUBLISHED)"
                mat-menu-item
              >
                {{ "Published" | translate }}
              </button>
              <button
                (click)="filterByStatus(PLANOGRAM_STATUS.DEPRECATED)"
                mat-menu-item
                *ngIf="companyType === 'MC1'"
              >
                {{ "Deprecated" | translate }}
              </button>
              <button
                (click)="filterByStatus(PLANOGRAM_STATUS.DRAFT)"
                mat-menu-item
              >
                {{ "Draft" | translate }}
              </button>
              <button
                (click)="filterByStatus(PLANOGRAM_STATUS.INACTIVE)"
                mat-menu-item
              >
                {{ "Inactive" | translate }}
              </button>
              <button (click)="filterByStatus(null)" mat-menu-item>
                {{ "All" | translate }}
              </button>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-chip-list aria-label="Fish selection">
              <mat-chip *ngIf="row.state == PLANOGRAM_STATUS.PUBLISHED">
                <span style="color: #048844"
                  >{{ "Published" | translate }}
                </span>
              </mat-chip>

              <mat-chip *ngIf="row.state == PLANOGRAM_STATUS.DEPRECATED">
                <span style="color: #c20e0e"
                  >{{ "Deprecated" | translate }}
                </span>
              </mat-chip>

              <mat-chip *ngIf="row.state == PLANOGRAM_STATUS.INACTIVE">
                <span style="color: #810caf">{{ "Inactive" | translate }}</span>
              </mat-chip>

              <mat-chip *ngIf="row.state == PLANOGRAM_STATUS.DRAFT">
                <span>{{ "Draft" | translate }}</span>
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <ng-container matColumnDef="lastUpdate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "UpdatedAt" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ formatDate(row.lastUpdate) }}
          </td>
        </ng-container>

        <ng-container
          *ngIf="companyType === 'Standalone'"
          matColumnDef="associated-users"
        >
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ 'Distributions' | translate }}"
              mat-button
              style="min-width: 1%"
              (click)="popupAssociatedUsers(row.id)"
            >
              <mat-icon style="color: #919191; transform: scale(0.9)"
                >assignment</mat-icon
              >
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="copyIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ 'Duplicate' | translate }}"
              mat-button
              style="min-width: 1%"
              (click)="copyPlanogram(row)"
            >
              <i class="pi pi-clone"></i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="planogramImage">
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let row"
            class="planogram-image-container pr-3"
          >
            <div
              (click)="showPlanogramImage(row)"
              *ngIf="row.imageUrl !== undefined"
              class="planogram-image"
              [ngStyle]="{ backgroundImage: 'url(' + row.imageUrl + ')' }"
            ></div>
          </td>
        </ng-container>

        <ng-container matColumnDef="deleteIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ 'Delete' | translate }}"
              *ngIf="row.state == PLANOGRAM_STATUS.DRAFT"
              mat-button
              style="min-width: 1%"
              (click)="deletePlanogram(row)"
            >
              <mat-icon fontSet="fa" fontIcon="fa-trash"></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="inactiveIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ 'InactivePlanogram' | translate }}"
              *ngIf="
                row.state == PLANOGRAM_STATUS.PUBLISHED && companyType !== 'MC1'
              "
              mat-button
              style="min-width: 1%"
              (click)="inactivePlanogram(row)"
            >
              <mat-icon fontSet="fa" fontIcon="fa-ban"></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="configIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              *ngIf="!hideConfig"
              matTooltip="{{ 'Config' | translate }}"
              (click)="updatePlanogramDetails(row.id)"
              mat-button
              style="min-width: 1%"
            >
              <i class="pi pi-cog"></i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="editIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ 'Editd' | translate }}"
              *ngIf="row.state == PLANOGRAM_STATUS.DRAFT"
              routerLink="/planogram-planning"
              [queryParams]="{ planogram_id: row.id }"
              mat-button
              style="min-width: 1%"
            >
              <i class="pi pi-pencil"></i>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="infoIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ 'Info' | translate }}"
              routerLink="/planogram-info"
              [queryParams]="{ planogram_id: row.id }"
              mat-button
              style="min-width: 1%"
            >
              <i class="pi pi-info-circle"></i>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <table-layout-footer>
        <div
          class="flex justify-content-end align-items-center"
          style="width: 100%; border-color: red"
        >
          <div>
            <mat-paginator
              style="background-color: transparent; padding: 0px"
              [length]="totalPlanograms"
              [pageSizeOptions]="[25, 50, 75, 100]"
              (page)="applyFilter()"
            >
            </mat-paginator>
          </div>
        </div>
      </table-layout-footer>
    </table-layout>
  </div>
</page-layout>

<div class="table-main d-none">
  <div class="planograms-table-content-container">
    <div class="planograms-table-header">
      <div class="planograms-table-title-container">
        <div class="title">
          {{ "Planograms" | translate }}
        </div>
        <div class="sub-title">
          {{ "PlanogramsCreatedByTheEditor" | translate }}
        </div>
      </div>
      <div>
        <button mat-flat-button class="addNew" (click)="addNewPlanogram()">
          <mat-icon>add</mat-icon>&nbsp;&nbsp;{{ "AddNEW" | translate }}
        </button>
      </div>
    </div>
    <div class="mat-elevation-z8">
      <div class="pre-table-header">
        <mat-form-field class="aligned">
          <input
            matInput
            (keyup)="listPlanogramsForAutocomplete($event, $event.target.value)"
            (keydown.enter)="applyFilter()"
            [(ngModel)]="searchPlanogramsValue"
            placeholder="{{ 'SearchPlanogram' | translate }}"
            [matAutocomplete]="searchPlanogramsAutocomplete"
          />
          <mat-autocomplete
            #searchPlanogramsAutocomplete="matAutocomplete"
            (optionSelected)="applyFilter()"
            (keydown.enter)="applyFilter()"
          >
            <mat-option
              *ngFor="let planogramName of filterPlanograms"
              [value]="planogramName"
            >
              {{ planogramName }}
            </mat-option>
          </mat-autocomplete>
          <mat-icon matSuffix *ngIf="autocompleteShowSpinner">
            <i class="fa fa-spinner fa-spin"></i>
          </mat-icon>
        </mat-form-field>
        <div class="pre-table-header-btn-container"></div>
      </div>

      <table
        mat-table
        [dataSource]="dataSource"
        matSort
        class="custom-table"
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="code">
          <th mat-header-cell *matHeaderCellDef>{{ "code" | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ row.mc1PlanogramId }}
            <span
              *ngIf="
                row.mc1PlanogramId === null || row.mc1PlanogramId === undefined
              "
              >{{ row.id }}</span
            >
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "Name" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">{{ row.name }}</td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>{{ "Type" | translate }}</th>
          <td mat-cell *matCellDef="let row">
            {{ displayPlanogramType(row.planogramType) | translate }}
          </td>
        </ng-container>

        <ng-container matColumnDef="validity">
          <th mat-header-cell *matHeaderCellDef>
            {{ "Validity" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ formatDate(row.validity) }}
          </td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>
            <button
              mat-button
              style="min-width: 1%"
              [matMenuTriggerFor]="statusesMenu"
            >
              <mat-icon>filter_list</mat-icon>
            </button>
            <mat-menu #statusesMenu="matMenu">
              <button
                (click)="filterByStatus(PLANOGRAM_STATUS.PUBLISHED)"
                mat-menu-item
              >
                {{ "Published" | translate }}
              </button>
              <button
                (click)="filterByStatus(PLANOGRAM_STATUS.DEPRECATED)"
                mat-menu-item
                *ngIf="companyType === 'MC1'"
              >
                {{ "Deprecated" | translate }}
              </button>
              <button
                (click)="filterByStatus(PLANOGRAM_STATUS.DRAFT)"
                mat-menu-item
              >
                {{ "Draft" | translate }}
              </button>
              <button
                (click)="filterByStatus(PLANOGRAM_STATUS.INACTIVE)"
                mat-menu-item
              >
                {{ "Inactive" | translate }}
              </button>
              <button (click)="filterByStatus(null)" mat-menu-item>
                {{ "All" | translate }}
              </button>
            </mat-menu>
            {{ "Status" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            <span
              *ngIf="row.state == PLANOGRAM_STATUS.PUBLISHED"
              style="color: #048844"
              >{{ "Published" | translate }}</span
            >
            <span
              *ngIf="row.state == PLANOGRAM_STATUS.DEPRECATED"
              style="color: #c20e0e"
              >{{ "Deprecated" | translate }}</span
            >
            <span
              *ngIf="row.state == PLANOGRAM_STATUS.INACTIVE"
              style="color: #810caf"
              >{{ "Inactive" | translate }}</span
            >
            <span *ngIf="row.state == PLANOGRAM_STATUS.DRAFT">{{
              "Draft" | translate
            }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="lastUpdate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "UpdatedAt" | translate }}
          </th>
          <td mat-cell *matCellDef="let row">
            {{ formatDate(row.lastUpdate) }}
          </td>
        </ng-container>
        <ng-container
          *ngIf="companyType === 'Standalone'"
          matColumnDef="associated-users"
        >
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ 'Distributions' | translate }}"
              mat-button
              style="min-width: 1%"
              (click)="popupAssociatedUsers(row.id)"
            >
              <mat-icon style="color: #919191; transform: scale(0.9)"
                >assignment</mat-icon
              >
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="copyIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ 'Duplicate' | translate }}"
              mat-button
              style="min-width: 1%"
              (click)="copyPlanogram(row)"
            >
              <mat-icon
                fontSet="fa"
                fontIcon="fa-copy"
                style="color: #919191"
              ></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="planogramImage">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="planogram-image-container">
            <div (click)="showPlanogramImage(row)" *ngIf="row?.imageUrlExternal || row?.imageUrl" class="planogram-image"
              [ngStyle]="{backgroundImage: 'url(' + (row?.imageUrlExternal && row?.imageUrlExternal?.trim() !== '' ? row.imageUrlExternal : row.imageUrl) + ')'}"></div>

          </td>
        </ng-container>
        <ng-container matColumnDef="deleteIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ 'Delete' | translate }}"
              *ngIf="row.state == PLANOGRAM_STATUS.DRAFT"
              mat-button
              style="min-width: 1%"
              (click)="deletePlanogram(row)"
            >
              <mat-icon
                fontSet="fa"
                fontIcon="fa-trash"
                style="color: #919191"
              ></mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="inactiveIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ 'InactivePlanogram' | translate }}"
              *ngIf="
                row.state == PLANOGRAM_STATUS.PUBLISHED && companyType !== 'MC1'
              "
              mat-button
              style="min-width: 1%"
              (click)="inactivePlanogram(row)"
            >
              <mat-icon
                fontSet="fa"
                fontIcon="fa-ban"
                style="color: #919191"
              ></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="configIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              *ngIf="!hideConfig"
              matTooltip="{{ 'Edit' | translate }}"
              (click)="updatePlanogramDetails(row.id)"
              mat-button
              style="min-width: 1%"
            >
              <mat-icon
                fontSet="fa"
                fontIcon="fa-cog"
                style="color: #919191"
              ></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="editIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ 'Edit' | translate }}"
              *ngIf="row.state == PLANOGRAM_STATUS.DRAFT"
              routerLink="/planogram-planning"
              [queryParams]="{ planogram_id: row.id }"
              mat-button
              style="min-width: 1%"
            >
              <mat-icon
                fontSet="fa"
                fontIcon="fa-edit"
                style="color: #919191"
              ></mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="infoIcon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row">
            <button
              matTooltip="{{ 'Info' | translate }}"
              routerLink="/planogram-info"
              [queryParams]="{ planogram_id: row.id }"
              mat-button
              style="min-width: 1%"
            >
              <mat-icon
                fontSet="fa"
                fontIcon="fa-info"
                style="color: #919191"
              ></mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        [length]="totalPlanograms"
        [pageSizeOptions]="[25, 50, 75, 100]"
        (page)="applyFilter()"
      >
      </mat-paginator>
    </div>
  </div>
</div>
